import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import { addToOrder, removeFromOrder } from '../actions';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,

} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleOutlineIcon from '@material-ui/icons/Remove';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
// import Slider from "react-slick";
import config, { getParameterByName, merchantCode } from '../Configs'

export default function ReviewScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const {
    orderItems,
    itemsCount,
    totalPrice,
    taxPrice,
    orderType,
    tableNumber,
  } = state.order;
  console.log(state.order);
  console.log(orderItems.image)
  const selectedCurrency = state.selectedCurrency;
  console.log(selectedCurrency);

  let { setting } = state.userSetting;
  console.log(setting);

  const baseURL = config.baseURL;

  // const isPaymentAllowed = getParameterByName("isPaymentAllowed");
  let isPaymentAllowed = setting ? setting.isPaymentAllowed : "";
  console.log(isPaymentAllowed);
  const [quantity, setQuantity] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState({});
  const closeHandler = () => {
    setIsOpen(false);
  };
  const productClickHandler = (p) => {
    console.log(orderItems);
    let existingOrder = orderItems.filter(o => o._id == p._id);
    setProduct(p);
    if (existingOrder.length) {
      setQuantity(existingOrder[0].quantity);
    } else {
      setQuantity(1);
    }
    setIsOpen(true);
  };
  let table = getParameterByName("tableNumber");
  console.log(table)
  const addToOrderHandler = () => {
    addToOrder(dispatch, { ...product, quantity });
    setIsOpen(false);
  };
  const cancelOrRemoveFromOrder = () => {
    removeFromOrder(dispatch, product);
    setIsOpen(false);
  };
  const procedToCheckoutHandler = () => {
    // procedToCheckout(dispatch);
    localStorage.setItem("order", JSON.stringify(state.order));
    if (isPaymentAllowed) {
      props.history.push('/select-payment?' + window.location.href.split('?')[1]);
    }
    else {

      props.history.push('/addmore?' + window.location.href.split('?')[1]);
    }
  };
  useEffect(() => { }, []);



  if (setting) {
    setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);

  }


  return (
    <Box className={[styles.root]} >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", height: "60px", padding: "10px" }}>
        <span style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "190px" }}>
          <span onClick={() => {
            props.history.push(`/order?` + window.location.href.split('?')[1]);
          }} className='back_arrow'>
            <img src="images/back.png" alt="BigCo Inc. logo" style={{ width: "20px", height: "20px" }} />
          </span>
          <h2>Table # {table}</h2>
        </span>

        <h1 align='start' id='rtxt'>View Order</h1>
        <div></div>

      </div>
      <Box className={[styles.main, styles.center]} id="reviewmain">
        <Dialog
          onClose={closeHandler}
          aria-labelledby="max-width-dialog-title"
          open={isOpen}
          fullWidth={true}
          // maxWidth="xs"
          maxWidth={state.widthScreen ? 'sm' : 'xs'}
          id="diaadd"
        >
          <DialogTitle className={styles.center}>
            Add {product.name}
          </DialogTitle>
          <Box className={[styles.row, styles.center]}>
            <Button className={styles.minus}
              id='minus_btn'
              variant="contained"
              color="primary"
              disabled={quantity === 1}
              onClick={(e) => quantity > 1 && setQuantity(quantity - 1)}
            >
              <RemoveIcon />
            </Button>
            <TextField
              id='order_count'
              inputProps={{ className: styles.largeInput }}
              className={styles.largeNumber}
              type="number"
              min={1}
              variant="filled"
              value={quantity}
            />
            <Button className={styles.add}
              id='plus_btn'
              variant="contained"
              color="primary"
              onClick={(e) => setQuantity(quantity + 1)}
            >
              <AddIcon />
            </Button>
          </Box>
          <Box className={[styles.row, styles.around]}>

            <Button
              onClick={cancelOrRemoveFromOrder}
              variant="outlined"
              color="primary"
              size="large"
              className={[styles.AddlargeButton]}
              style={{ color: "black" }}
            >
              {orderItems.find((x) => x.name === product.name)
                ? 'Remove'
                : 'Cancel'}
            </Button>

            <Button
              onClick={addToOrderHandler}
              variant="contained"
              color="primary"
              size="large"
              className={styles.rightlargeButton}
            >
              ADD
            </Button>
          </Box>
        </Dialog>


        <Box >


        </Box>
        <Box style={{ overflowY: "auto", padding: "5px", width: "100%", height: "100%" }}>
          <table align="center" style={{ width: "95%" }} cellPadding="3px" cellSpacing="2px">
            <thead>
              <tr style={{ fontSize: "20px", textAlign: "left", lineHeight: "2", fontFamily: "Arial", boxShadow: "0px 2px 0px black", paddingBottom: "20px" }} id="tablehead">
                <th id='rev-img'></th>
                <th>Item Name</th>
                <th>Quantity</th>
                <th>Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ height: "100%", overflow: "scroll" }}>
              {orderItems.length ? orderItems.map((orderItem) => {
                   const subProArray = orderItem.sub_pro && JSON.parse(orderItem.sub_pro);
                   const subProNames = subProArray ? subProArray.map(subPro => subPro.name) : [];
                   console.log(orderItem.sub_pro)
                   console.log(subProArray)
                return <>

                  <tr style={{ textAlign: "left", fontSize: "15px", fontFamily: "Arial", boxShadow: "0px 2px 0px lightgray" }} id='tdtab'>
                    <td id='rev-img'>
                      <img src={baseURL + "/" + orderItem.image} style={{ height: "80px", width: "80px", borderRadius: "22px" }} />

                    </td>
                    <td id='txttab' > <b>{orderItem.name} </b>  <br /> <i>  { subProNames.length > 0 ? "Addons:" + subProNames.join(', ') : subProNames}  </i> <br /> <i>{orderItem.cookInstructions ? "Instructions:" + orderItem.cookInstructions : ""}</i></td>
                    <td><h4>{orderItem.quantity}</h4></td>
                    <td><h4>{selectedCurrency} {orderItem.price + (subProArray ? subProArray.reduce((acc, val) => acc + val.price, 0) : 0)}</h4></td>
              

                    <td >

                      <EditIcon className='rev_edit' onClick={() => productClickHandler(orderItem)} />

                      <RemoveCircleOutlineIcon className='rev_remove' onClick={() => removeFromOrder(dispatch, orderItem)} />
                    </td>
                  </tr>
                </>
              })

                : ""}
            </tbody>
          </table>
        </Box>


      </Box>

      <Box>
        <Box>
          <Box className={[styles.row, styles.end]} style={{ paddingRight: "40px" }} id="placeo-box">
            {/* <button
              onClick={() => {
                props.history.push(`/order?` + window.location.href.split('?')[1]);
              }}
              style={{background:"grey",margin:"0ox 44px"}}
              className="back"
              id='review_clbtn'
            >
              Back
            </button> */}

            <button
              onClick={procedToCheckoutHandler}
              style={{ background: "rgb(255, 188, 0)", color: "#000", margin: "0ox 44px", fontWeight: "bold" }}
              disabled={orderItems.length === 0}
              className="place_order"
            >
              Place Order
            </button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
