import React, { useContext, useEffect } from 'react';
import Axios from 'axios';
import { Box, Button, CardMedia, CircularProgress, Typography } from '@material-ui/core';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import { Store } from '../Store';
import { closeQrCode } from '../actions';
import QRCode from "react-qr-code";
import {
  clearOrder,

} from '../actions';



export default function CompleteOrderScreen(props) {
  const getParameterByName = (e, t = window.location.href) => {
    e = e.replace(/[\[\]]/g, "\\$&"); var n = new RegExp("[?&]" + e + "(=([^&#]*)|&|#|$)").exec(t);
    return n ? n[2] ? decodeURIComponent(n[2].replace(/\+/g, " ")) : "" : null
  }

  let sokURL = window.location.href.indexOf('localhost') > 0 ?'https://sok.menulive.in':window.location.origin;
 const baseURL = sokURL.replace("sok","api");
  const payURL = "https://pay.digitallive24.com";
  const { state, dispatch } = useContext(Store);
  const { selectedCurrency, order, newQr, fetchQr } = state;
  let paytimer = null;
  console.log(newQr);
  const styles = useStyles();

  console.log(fetchQr);
  console.log(newQr);


  useEffect(() => {
    if (newQr && newQr.data) {
      console.log(newQr.data);
    }
  }, []);



  const checkPaymentStatus = async (payId) => {
    try {
      const { data } = await Axios.get(`${payURL}/api/payIntentStatus/${payId}`);
      console.log(data);
      if (data && data.length && data[0].payStatus.toUpperCase() === "PAID") {
        if (paytimer) clearInterval(paytimer);
        return props.history.push('/complete?' + window.location.href.split('?')[1]);
      }

    }
    catch (error) {
      console.log(error);

    }
  };

  const previewOrderHandler = () => {
    props.history.push(`/review?` + window.location.href.split('?')[1]);
  };



  function startTimer(newQr) {
    var duration = 60 * 10;
    var timer = duration, minutes, seconds;
    if (paytimer) clearInterval(paytimer);
    paytimer = setInterval(function () {


      let display = document.getElementById('time');
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      display.innerHTML = minutes + ":" + seconds;

      if (--timer < 0) {
        timer = duration;
        if (paytimer) clearInterval(paytimer);
        console.log("Qr Expired");

        return props.history.push(`/select-payment?` + window.location.href.split('?')[1]);
      }

      if (seconds % 10 === 0 && newQr.data && newQr.data.payIntent) {
        console.log("hello");
        checkPaymentStatus(newQr.data.payIntent);
      }
    }, 1000);

  }

  if (!paytimer && newQr && newQr.data && newQr.data.url) {
    startTimer(newQr);
  }





  return (
    <>
      <Box className={[styles.root, styles.navy]} style={{ backgroundColor: state.selectedBgColor }}>
        <Box className={styles.root1}>

          {newQr && newQr.data && newQr.data.url && <div><div style={{ backGround: 'red' }} className={styles.timer}>QR Code expired in  <span id="time" className={styles.timerSize}> </span>  minutes!</div>

            <div className={styles.qrCode}>
              <CardMedia
                component="img"
                image={newQr.data && newQr.data.image_url ? newQr.data.image_url : ""}
                className={styles.qr_img}

              />

            </div></div>}

          {newQr && newQr.data && newQr.data.url &&
            <div>
              <h4 className={styles.qrTitle}>Scan with your mobile's camera to pay</h4>
              <div className={styles.qrCodeGen} >
                <QRCode
                  size={456}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={newQr.data.url}
                  viewBox={`0 0 456 456`}
                />
              </div></div>}

        </Box>
        <Box className={[styles.row, styles.around, styles.space]}>
          <Button
            onClick={() => {
              if (paytimer) clearInterval(paytimer);
              clearOrder(dispatch);
              props.history.push('/?' + window.location.href.split('?')[1]);
            }}
            variant="outlined"
            color="primary"
            className={styles.largeButton}
          >
            Cancel Order
          </Button>

          <Button
            onClick={previewOrderHandler}
            variant="contained"
            color="primary"
            className={styles.largeButton}
          >
            BACK
          </Button>
        </Box>
      </Box>
    </>
  );
}
