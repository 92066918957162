import React,{useContext,useEffect} from "react";
import { useStyles } from '../styles';
import { Store } from '../Store';
import axios from "axios";


import {
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    CircularProgress,
    Dialog,
    DialogTitle,
    Grid,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    Slide,
    TextField,
    Typography,
  } from '@material-ui/core';
  import { setTableNumber,createOrder, setPaymentType, generateQrCode, clearOrder, getCheckoutUrl,getUserData,fetchOrder,updateOrder } from '../actions';
import { getParameterByName } from "../Configs";
  function AddMoreScreen(props){
    const styles = useStyles();
    const { state, dispatch } = useContext(Store);
    const { order, selectedCurrency } = state;
    const { loading, error, newOrder } = state.orderCreate;
    const {
        orderItems,
        itemsCount,
        totalPrice,
        taxPrice,
        orderType,
        tableNumber,
      } =order;
    
      const table = getParameterByName("tableNumber")

// console.log(state);

useEffect(() => {
    console.log("gdjkhlk");
    let sessionOrder=JSON.parse(localStorage.getItem("newOrder"))
    if(sessionOrder&&sessionOrder.id){
      let items = order.orderItems.map((o) => {
        if(!o.status){
          o["status"] = "inProgress";
        }
    return o;
  });

  // localStorage.setItem("tableNumber",order.tableNumber);
  localStorage.setItem("itemsCount",order.itemsCount);
      order.orderItems = items;
      updateOrder(dispatch,order,sessionOrder?sessionOrder.id:'');
    }
    else{
    if (order.orderItems.length > 0) {
      
      localStorage.setItem("itemsCount",order.itemsCount);

      let items = order.orderItems.map((o) => {
            if(!o.status){
              o["status"] = "inProgress";
            }
        return o;
      });

    
      

    order.orderItems = items;
    order.paymentType = order.paymentType ? order.paymentType : "At Counter";
    // order.paymentType = isPaymentAllowed ? order.paymentType : "At Counter";
    createOrder(dispatch, order);

  }
}

}, []);
console.log(newOrder);

if(newOrder){
  console.log("dfglliurghrg");
localStorage.setItem("newOrder",JSON.stringify(newOrder));
}

let orderStored = localStorage.getItem("newOrder") ? JSON.parse(localStorage.getItem("newOrder")):[];
console.log(orderStored);

 const fetch =()=>{
  newOrder?fetchOrder(dispatch,orderStored?orderStored.id:""):console.log("object");
 }
 
 useEffect(()=>fetch(),[]);
 console.log(state.fetchOrder.data);

let mapingOrder =newOrder?newOrder:orderStored?orderStored:" ";
console.log(mapingOrder);
    return(
        <Box className={styles.root} style={{backgroundColor: state.selectedBgColor,height:"100%",overflow:"hidden !important"}} >
                  <div style={{display:"flex", justifyContent:"space-between",alignItems:"center",width:"100%",height:"60px"}}>
                <span style={{display:"flex", justifyContent:"flex-start",alignItems:"center",width:"190px"}}>
                  <span onClick={() => {
                    clearOrder(dispatch);
                    props.history.push(`/review?` + window.location.href.split('?')[1]);
                  }} className='back_arrow'>
                        <img src="images/back.png"alt="BigCo Inc. logo"  style={{ width: "20px",height:"20px"}} />
                  </span>
                    <h2>Table #  {table}</h2>
                </span>
               
                  <Typography
                    gutterBottom
                    // className={styles.title}
                    className={styles.title3}
                    variant="h6"
                    component="h6"
                    id="title1"
                  >
                   Order # {mapingOrder&&mapingOrder.number?mapingOrder.number:""}
                  </Typography>
               
                <div></div>

        </div>
        <Box className="admor">   
        <Box className="admore_items">

            <Box  className="admore_sub">
            
              <h3 align="center" style={{fontSize:"20px",fontFamily:"arial"}} >Order Status</h3>
              <table cellPadding="10px" cellSpacing="10px" align="center" id="tab">
                <tr style={{fontSize:"22px",fontFamily:"arial", textAlign:"left"}} id="adhaead">
                  <th>Item</th>
                  <th>Status</th>
                </tr>
              {mapingOrder&&mapingOrder.orderItems&&mapingOrder.orderItems.length?mapingOrder.orderItems.map((orderItem) => (
                <tr style={{fontSize:"22px",fontFamily:"arial",textAlign:"left"}} id="adbody">
                <td>{orderItem.name}</td>
                <td id="status">{orderItem.status==="inProgress"?"Preparing":"Ready"}</td>
                <td></td>
              </tr>
              )):""}
              </table>

            </Box>


              <Box className="admore_sub1" >
                 <div style={{margin:"40px 0px"}} id="adtxt">
                  <h3>Want to Order More?</h3>
                 <button
                    onClick={() => {
                      setTableNumber(dispatch,localStorage.getItem("tableNumber"))
                      fetch();
                      props.history.push(`/order?` + window.location.href.split('?')[1]);
                    }}
                    className="admore_btns"
                  >
                    ADD MORE
                  </button>
                 </div>

                  <div style={{margin:"40px 0px"}}>
                    <h3>Done with your meal</h3>
                        <button
                        className="admorebtn"
                        //   onClick={procedToCheckoutHandler}
                          // disabled={mapingOrder&&mapingOrder.orderItems.length === 0}
                          onClick={() => {
                            fetch();
                            props.history.push(`/complete?` + window.location.href.split('?')[1]);
                          }}
                         
                        >
                       Make Payment
                        </button>
                  </div>
                </Box>

        </Box>
        </Box>

            
        </Box>
    )
}

export default AddMoreScreen;