import React, { useContext, useEffect, useState } from 'react';
import { Store } from '../Store';
import axios from "axios";
import Slider from "react-slick";
import {
  addToOrder,
  clearOrder,
  listCategories,
  listProducts,
  removeFromOrder,
  getUserData
} from '../actions';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Alert } from '@material-ui/lab';
import { useStyles } from '../styles';
import Logo from '../components/Logo';
import config,{getParameterByName,merchantCode} from '../Configs'
import Checkbox from '@mui/material/Checkbox';


export default function OrderScreen(props) {
  const styles = useStyles();
  const { state, dispatch } = useContext(Store);
  const { categories, loading, error } = state.categoryList;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [procheckbox, setProCheckBox] = useState([]);
  const [cookInst, setCookInst] = useState([])
  const [cookalignment, setCookAlignment] = useState([]);
  

  const {
    products,
    loading: loadingProducts,
    error: errorProducts,
  } = state.productList;

  const {
    orderItems,
    itemsCount,
    totalPrice,
    taxPrice,
    orderType,
    tableNumber,
  } = state.order;
  console.log(state.order);
  console.log(orderItems)
  const selectedCurrency = state.selectedCurrency;
   
  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
 let cat_time =null;
  console.log(state.userSetting);

  const[totalProducts,setTotalProducts]=useState([]);

const [totalAddons,setTotalAddons]=useState([]);
const [itemGroup,setItemGrorp]=useState([]);
const[filterItmGrp,setFilterItmGrp]=useState([]);
const[itemGrpId,setItemGrpId]=useState("");

const[addonsGroup,setAddonsGroup]=useState([]);


const [catId,setCatId]=useState("")
  const [categoryName, setCategoryName] = useState('');
  const [foodName, setFoodName] = useState("");
  const [tags, setTags] = useState([]);
  const [setCategories] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [imageURL, setImageURL] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isVegOnly, setIsVegOnly] = useState(false);
  const [addons,setAddons]=useState([]);
const [subPro,setSubPro]=useState([]);
const [selectedLogo, setSelectedLogo] = useState('../images/logo.png');
const[isPaneOpen,setIsPaneOpen]=useState(false);
const [alignment, setAlignment] = useState('left');
const [variety, setVariety] = useState([]);
const[isCartOpen,setIsCartOpen]=useState(false);


  const baseURL = config.baseURL;
  console.log(products);

  // if(tableNumber===0){
  //   props.history.push(`/?` + window.location.href.split('?')[1]);
  // }
const table = getParameterByName("tableNumber")
  let filteredProducts = [];
  if (products) {
    filteredProducts = products.filter(p => ((isVegOnly && p.cat_type.toUpperCase() === "VEG" && p.inStock) || !isVegOnly && p.inStock));
  }
  
  console.log(filteredProducts);
 let {setting} = state.userSetting;
 let {userInfo}=state.userData;
    console.log(setting,userInfo);
    if(setting){
      let body = document.getElementsByTagName("body");
     //body[0].style.backgroundColor = setting.color_tirnary;
     setTimeout(() => {
      let textcolor = document.getElementById("title1");
      textcolor.style.color = setting.color_primary;
    }, 10);

   }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows:true,
    slidesToScroll: 1,
    // vertical: true,
    // verticalSwiping: true,
  };


  const [product, setProduct] = useState([]);
  console.log(product);

  const closeHandler = () => {
    setIsOpen(false);
  };


  const vegHandler = () => {
    console.log(products);
    setIsVegOnly(!isVegOnly);
  }

  useEffect(()=>{
    if(!totalProducts.length){
      axios.get(`${baseURL}/api/products?merchantCode=${merchantCode}`)
      .then(res=>setTotalProducts(res.data))
    }
  })

  useEffect(()=>{
    console.log(totalProducts);
    console.log(categories)
    let addons= [];
    categories && categories.map(c => {if(c.isAddOn){addons.push(c.id);}});
    setAddonsGroup(totalProducts.filter(pro => addons.indexOf(pro.category) != -1));
    if(totalProducts.length){
      let items=[]
      totalProducts.filter(it=>{
       categories.map(p=>{
                  if(p.id===it.category){
                    items.push(it) ;
                    console.log(it)
                  }
                })
      })
      console.log([...new Set(items)]);
      setFilterItmGrp([...new Set(items)])
    }else if(products&&products.length){
      // setFilterItmGrp(itemGroup.filter(i=>i.category===catId?catId:categories[0]._id?categories[0]._id:categories[0].id));
      setFilterItmGrp(products);
    }
  },[products])
console.log(products);
  console.log(filterItmGrp);

  const handleCookAlignment = (event, newAlignment) => {
    setCookAlignment(newAlignment)
  }
  const productClickHandler = (p) => {
    console.log(p._id);
console.log(addonsGroup)
    console.log(orderItems);

    if (p.varietyPrices || p.cookInstructions) {
      setVariety(JSON.parse(p.varietyPrices));
      setCookInst(p && p.cookInstructions ? p.cookInstructions.split(",") : [])
    }

   

    
    setProduct(p);
    console.log(p)
    const paddon = p.add_ons.split(",").filter(a => a.length);
    console.log(paddon)
    console.log(addonsGroup)

    let addName = addonsGroup.filter(li => paddon.indexOf(li.id) != -1);
console.log(addName)
    setAddons(addName);
    setSelectedProduct(p);
    setIsOpen(true);
  };
  console.log(addons);


  const addToOrderHandler = () => {
    product["sub_pro"] = JSON.stringify(subPro.map(li => li));


    console.log(product);
    let adons = totalAddons.map(ad => {
      ad.quantity = 0;
      return ad
    })
    const selectedVarietyPrice = Object.keys(variety).reduce((acc, key) => {
      if (key === alignment) {
        acc = variety[key];
      }
      return acc;
    }, 0);

if(variety){

  const varietyPrice = parseFloat(selectedVarietyPrice);
  selectedProduct.price = varietyPrice;
}

    console.log(selectedProduct)
    addToOrder(dispatch, { ...product, quantity });

    console.log(orderItems)


    setTotalAddons(adons);
    setAddons([]);
    setQuantity(1);
    setSubPro([]);
    setSelectedProduct()
    // setAddons([]);
    setIsOpen(false);

  };
  const cancelOrRemoveFromOrder = () => {
    let adons= totalAddons.map(ad=>{
      ad.quantity=0;
    return ad
    })

    setTotalAddons(adons);
    setAddons([]);
    setSubPro([]);
    removeFromOrder(dispatch, product);
    setIsOpen(false);
  };
  const previewOrderHandler = () => {
    props.history.push(`/review?` + window.location.href.split('?')[1]);
  };

  useEffect(()=>{
    getUserData(dispatch);
  },[]);

  console.log(!categories);
  
  useEffect(() => {
    if (!categories) {
      listCategories(dispatch);
      console.log(listCategories(dispatch));
    } else {
      listProducts(dispatch, categories[0]._id?categories[0]._id:categories[0].id);
    }
  }, [categories]);

  
  const categoryClickHandler = (cat_name, cat_id) => {
    console.log(cat_id);
    setCatId(cat_id);
    setCategoryName(cat_name);
    listProducts(dispatch, cat_id);
    setQuantity(1);
  };

  const handleCart =()=>{
    console.log("Cart");
    props.history.push(`/review?` + window.location.href.split('?')[1]);

  }
  const removeAddons=(itemId)=>{
    let filterItems=addons.filter(i=>(i._id?i._id:i.id)===itemId);
    let existingItem =subPro.filter(p=>(p._id?p._id:p.id)===itemId);
    console.log(subPro);
    console.log(existingItem);
    if(existingItem.length === 0){
      filterItems[0].quantity = filterItems[0].quantity > 0?filterItems[0].quantity-1:filterItems[0].quantity;
    }
    console.log(filterItems);

    const items  = existingItem.length > 0?
                    subPro.map((x) =>{
                      if((x._id?x._id:x.id)===itemId){
                        console.log(x.quantity,existingItem[0].quantity);
                        x.quantity = x.quantity > 0 ? x.quantity-1 : x.quantity;
                      }
                      return x;
                    }):[...subPro,filterItems[0]];
       
          setSubPro(items);     
  }


  const adAddons = (e, itemId, index) => {
    const updatedCheckBox = [...procheckbox]; 
    updatedCheckBox[index] = e.target.checked;
    setProCheckBox(updatedCheckBox);
    
    const filterItems = addons.filter(i => (i._id ? i._id : i.id) === itemId);
    console.log(filterItems);
    
    const existingItem = subPro.find(p => (p._id ? p._id : p.id) === itemId);

    if (!existingItem) {
        setSubPro(prevSubPro => [...prevSubPro, ...filterItems]);
    }
}


  const imageOnErrorHandler = (event) => {
    event.currentTarget.src = "./images/blank.jpg";
  }

  const itemGroupHandle =(itmgrpId)=>{
       setItemGrpId(itmgrpId);
  }

  let  ItemsWithGrp = filterItmGrp.length?filterItmGrp:products;
  const itemsfilt = totalProducts.filter(proitem => proitem.category === catId )
console.log(itemsfilt)
const showVarietyBtn = (variety) => {
  return <ToggleButtonGroup
  value={alignment}
  onChange={handleAlignment}
  exclusive
  aria-label="text alignment"
  style={{backgroundColor:"white"}}
>{
  Object.keys(variety).map((key,index) =>(
    <ToggleButton style={{display:"inline-block"}} value={key} aria-label="left aligned">
      <div style={{display:"block",width:"100%"}}>{key}</div>
      <div style={{color:"#000",fontWeight:"bold",fontSize:"1.2em"}}>{selectedCurrency + variety[key]}</div>
    </ToggleButton>)
  )
  }
  </ToggleButtonGroup>
  
}

const showinstructionBtn = () => {
  return <ToggleButtonGroup
    value={cookalignment}
    onChange={handleCookAlignment}
    exclusive
    aria-label="text alignment"
    style={{backgroundColor:"white"}}
  >{
      Object.values(cookInst).map((key, index) => (
        <ToggleButton style={{ display: "inline-block" }} value={key} aria-label="left aligned">
          <div style={{ display: "block", width: "100%" }}>{key}</div>
        </ToggleButton>)
      )
    }
  </ToggleButtonGroup>
}
  return (
    <Box className={styles.root2} style={{backgroundColor: state.selectedBgColor, height:"100% !important"}} >

      <div style={{display:"flex", justifyContent:"space-between",alignItems:"center",width:"100%",height:"60px"}}>
        <span style={{display:"flex", justifyContent:"flex-start",alignItems:"center",width:"auto"}}>
           <span onClick={() => {
            clearOrder(dispatch);
            props.history.push(`/?` + window.location.href.split('?')[1]);
          }} className='back_arrow'>
                <img src="images/back.png"alt="BigCo Inc. logo"  style={{ width: "20px",height:"20px"}} />
          </span>
            <h2 style={{marginLeft:"110px"}} id='tableno'>Table # {table}</h2>
        </span>
        <div className='cart_container' onClick={handleCart} >
        
        <div className='cart_dox'>
          <img src='images/cartnew.png' className='cart_box_img'/>
          </div>
        <span className='cart_count'>{itemsCount}</span>
    </div>
         </div>
        
         <Dialog
                onClose={()=>setIsCartOpen(false)}
                aria-labelledby="max-width-dialog-title"
                style={{backgroundColor:"#fff !important"}}
                open={isCartOpen}
                fullWidth={true}
                maxWidth="lg"
              >
                  <div className='cart_header' > 
                          <span onClick={() => {setIsCartOpen(false)}} className='back_arrow'>
                            <img src="images/back.png"alt="BigCo Inc. logo"  style={{ width: "20px",height:"20px"}} />
                          </span>My Order
                  </div>
                  <div className='cart_sub'>
                        <div className='cart_items'>
                                  <div align="end">
                                  {orderItems.length?<button className="remove_all"  onClick={()=>{
                                      clearOrder(dispatch);
                                      // setIsCartOpen(false);
                                    }}>Remove All</button>:""}
                                  </div>

                                    {orderItems.length?orderItems.map(pro=>{
                                      return<div style={{fontSize:"15px",padding:"10px"}}>
                                        <table cellPadding="8px" cellSpacing="5px">
                                            <tr>
                                                  <th>Item Name</th>
                                                  <th>Quantity</th>
                                                  <th>Price</th>
                                              </tr>
                                              <tr  >
                                                <td style={{width:"100px"}}>{pro.name}</td>
                                                <td>{pro.quantity}</td>
                                                <td>{selectedCurrency} {pro.price}</td>
                                              </tr>
                                      </table>
                                        <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>
                                            <button className='cart_edit' onClick={()=>productClickHandler(pro)} >Edit</button>
                                            <button className='cart_remove' onClick={()=>removeFromOrder(dispatch,pro)} >Remove</button>
                                        </div>
                                        <hr style={{fontSize:"24px", borderRadius:"5px",height:"3px",background:"#434343"}}></hr>
                                   </div>
                                    })
                                   :
                                   <div style={{display:"flex",justifyContent:"center",alignItems:"center",fontSize:"20px"}}>
                                    No Items Available
                                   </div>
                                   }
                            
                        </div>

                            <div className='cart_items'>
                                <h3 style={{fontSize: "25px",marginTop: "14px",marginLeft:"2px"}}>Bill Summery</h3>
                                  <table style={{width:"100%",fontSize:"20px"}}>
                                            <tr>
                                              <td>Sub - total :</td>
                                              <td align='end'>{selectedCurrency} {(totalPrice-taxPrice).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                              <td>Tax :</td>
                                              <td align='end'>{selectedCurrency} {taxPrice.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                              <td>Discount :</td>
                                              <td align='end'>{selectedCurrency} 0.00</td>
                                            </tr>
                                  </table>
                                  <div style={{position:"relative",top:'120px'}}>

                                        <table style={{width:"100%",fontSize:"20px",marginBottom:"30px"}} >
                                              <tr>
                                                <th style={{textAlign:"start"}}>Total amount :</th>
                                                <th style={{textAlign:"end"}}>{selectedCurrency}{totalPrice.toFixed(2)}</th>
                                              </tr>
                                        </table>

                                      <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                        <button className='cart_cancle'
                                         onClick={() => {
                                          clearOrder(dispatch);
                                          setIsCartOpen(false)
                                          // props.history.push('/choose?' + window.location.href.split('?')[1]);
                                        }}
                                        >Cancel</button>

                                        <button className='cart_procee'  onClick={previewOrderHandler}>Proceed</button>
                                      </div>
                                  </div>

                            </div>
                  </div>

              </Dialog>
          
    <Box id="box">

      <Slider {...settings}  style={{width:"12px !important"}}>
        <div className={styles.categoryContainer} id="cate-item">

          {categories && categories.map((category) => (

            <div className={styles.categoryItem} id='cat-item' onClick={() => categoryClickHandler(category.name, category._id?category._id:category.id)} >
              <div className={styles.imageHolder}>
                <img className={styles.image} alt={category.name}  onError={imageOnErrorHandler} src={`${baseURL}/` + category.image} />
              </div>
              <div>
                {category.name}
              </div>
            </div>

          ))}
          
        </div>
      </Slider>
     
      <Box className={styles.main} id="probox">
      <Typography
              gutterBottom
              className={[styles.title, styles.catTitleBox]}
              variant="h2"
              component="h2"
              id='tit1'
              style={{marginBottom:"10px",marginLeft:"20px",display:"flex",justifyContent:"space-between",alignItems:"center",height:"20px"}}
            >
              <span id="txt">{categoryName || 'Explore our menu'}</span>

              <div className={styles.vegBtn} >
                <FormGroup>
                  <FormControlLabel control={<Switch size='medium' defaultUnChecked color='success' />} onChange={vegHandler} style={{ color: "green", fontWeight: "400px" }} label="Only Veg" />
                </FormGroup>
              </div>
            </Typography>
            <div className= {styles.cont} id="btns">
               <div className={styles.btnHolder}
         id='btnc1'
               >
                All
               </div>
               <div className={styles.btnHolder} style={{marginLeft:"10px"}}  id='btnc2'>
                Special
               </div>
               <div className={styles.btnHolder}
               style={{backgroundColor:"#FFBC00",color:"#000",marginLeft:"10px" }}  id='btnc3'
               >
                Popular
               </div>
            </div>
            <Dialog
          onClose={closeHandler}
          aria-labelledby="max-width-dialog-title"
          open={isOpen}
          style={{height:"80 !important"}}
          fullWidth={true}
          maxWidth={state.widthScreen ? 'sm' : 'xs'}
        > 
          <Box className={styles.center} style={{width:"100% !important",height:"35%"}} id="dailogbox"> 
           
          <Card
          className={styles.cardd }
          style={{width:"80%",height:"85%",display:"flex", justifyContent:"space-evenly",alignItems:"center"}}
          id="card-d"
          >
          

            <CardMedia 
              component="img"
                  alt={product.name}
                  image={`${baseURL}/` + product.image}
                  // className={styles.media}
                  onError={imageOnErrorHandler}
                  style={{paddingTop:"10px !important",height:"80%",width:"40%",borderRadius:"18px"}}
                  id="img-d"
                          />
                    <div style={{padding:"5px"}} id="d-card">
                      <span align="start" style={{fontSize:"20px",fontWeight:"bold"}} id='d-h3'> {product.name} </span>
                              <div style={{width:"100%",display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                                {product.calorie?<span><b style={{fontSize:"14px",fontWeight:"200"}}>Cal {product.calorie}</b></span>:<span></span>}
                                <span><b style={{fontSize:"20px"}}>{selectedCurrency} {product.price}</b></span>
                              </div>
                          <div align="start" style={{marginTop:"18px"}}>
                            {product.description?product.description:"No Product Description"}
                          </div>
                    </div>
           </Card>      
          </Box>
          <Box className={[styles.countRow, styles.center]} id='boxop' >
            <Button className={styles.minus}
               id='minus_btn'
              variant="contained"
              color="primary"
              disabled={quantity === 1}
              onClick={(e) => quantity > 1 && setQuantity(quantity - 1)}>
              <RemoveIcon />
            </Button>
            <TextField
              inputProps={{ className: styles.largeInput }}
              InputProps={{
                bar: true,
                inputProps: {
                  className: styles.largeInput,
                },
              }}
              // className={styles.largeNumber}
              className='largeNumber'
              type="number"
              variant="filled"
              min={1}
              value={quantity}
            />

            <Button className={styles.add}
              id='plus_btn'
              variant="contained"
              color="primary"
              onClick={(e) => setQuantity(quantity + 1)}
            >

              <AddIcon sx={{ fontSize:"1px"}}/>
            </Button>
          </Box>
          <Box style={{margin:"10px",overflow:"auto",height:"50%"}} id="addons-box">
          <h4 style={{ textAlign: "center" }}>{"SELECT SIZE"}</h4>
              <div style={{textAlign:'center',fontWeight:"bold"}}>
              {showVarietyBtn(variety)}
              </div>
              <h4 style={{ textAlign: "center" }}>{"SELECT COOKING INSTRUCTION"}</h4>
              <div style={{ textAlign: 'center', fontWeight: "bold" }}>
                {showinstructionBtn(cookInst)}

              </div>
              <h4 style={{ textAlign: "center" }}>{addons.length >= 1 ? "Add-ons" : ""}</h4>
            {addons.length >= 1 ? addons.map((li, i) => (
              <div id="addons" style={{ display: "flex", justifyContent: "space-between", alignContent: "center", padding: "3px 15px", fontSize: "20px", flexWrap: "wrap" }}>
                <span id='addname'> {li.name} </span>
                <div>
                  <span id='pricheck' i>{selectedCurrency} {li.price}
                    <Checkbox
                      id={`checkboxId-${i}`}
                      checked={procheckbox[i]}
                      onChange={(e) => adAddons(e, li._id ? li._id : li.id, i)}
                    /></span>
                </div>
              </div>
            ))
              : ""}
          </Box>
          <Box id="footorder" className={[styles.row, styles.around]}>
            <Button
              onClick={cancelOrRemoveFromOrder}
              size="large"
              className={[styles.AddlargeButton]}
              style={{backgroundColor:"#FFF !important",color:"#000 !important",border:"2px solid black"}}
              id="btcart"
            >
              {orderItems.find((x) => x.name === product.name)
                ? 'Remove'
                : 'Cancel'}
                {/* Add */}
            </Button>

            <Button
              onClick={addToOrderHandler}
              variant="contained"
              size="large"
              className={styles.rightlargeButton}
              id="btcart"
            >
              Add
            </Button>
          </Box>
        </Dialog>
        <Grid container>
          <Grid item md={12} xs={12} id="pro-con">


            <Grid container spacing={1} id="pro_continer">

{catId ? (
                     itemsfilt.map((it) =>{
                      return (<>
                        <Slide key={it.name} direction="up" in={true} >
                        
                            <Card
                              className={styles.card}
                              onClick={() =>it["userId"]?productClickHandler(it):itemGroupHandle(it.id)}
                              style={{width:"205px",height:"260px"}}
                              id="product"
                            >
                              <CardActionArea style={{display:"flex",flexDirection:"column",paddingTop:"4px",flexWrap:"wrap"}}>
                                <CardMedia
                                  component="img"
                                  alt={it.name}
                                  onError={imageOnErrorHandler}
                                  image={`${baseURL}/` + it.image}
                                  className={styles.media}
                                  id='img-product'
                                />
                                <span id="pri">
                                {selectedCurrency} {it.price}
                                </span>
                                <CardContent className={styles.cardFooter}>
                                  <Box className={styles.foot}>
                                    <Typography
                                      gutterBottom
                                      className={styles.prod_title}
                                      variant="h6"
                                      color="primary"
                                      component="p"
                                      id="txtf"
      
                                    >
                                      {it.name} <br/>
                                     
      
                                    </Typography>
                                    {/* <Typography
                                      variant="subtitle1"
                                      className={styles.prod_cal}
                                      component="p"
                                    >
                                      {product.calorie} Cal
                                    </Typography> */}
                                     
                                  </Box >
                                </CardContent>
                              </CardActionArea>
                            </Card>
                      
                        </Slide>
      
                      <Dialog
                      onClose={()=>setItemGrpId("")}
                      aria-labelledby="max-width-dialog-title"
                      fullScreen="sm"
                      open={itemGrpId===it.id}
                      fullWidth={true}
                      // maxWidth="sm"
                            maxWidth={state.widthScreen ? 'lg' : 'md'}
                            > 
                             <AppBar sx={{ position: 'relative',marginBottom:"18px" }}>
                                    <Toolbar>
                                      {/* <IconButton
                                        edge="start"
                                        color="error"
                                        style={{fontSize:"18px"}}
                                        onClick={()=>setItemGrpId("")}
                                        aria-label="close"
                                      >
                                      Back
                                      </IconButton> */}
                                      <Typography style={{marginLeft:"25px"}} color="secondary" sx={{ ml: 2, flex: 1 }} variant="h5" component="h5">
                                        {it.name}
                                      </Typography>
                                    </Toolbar>
                                  </AppBar>
      
                                <Grid container spacing={1}  sx={{ height:"80%",padding:"15px" }}>
      
                                  {
                                    filterItmGrp.filter(p=>p.id===itemGrpId).map((item) =>{
                                     return item.items.elements.filter(p=>p.available).map((product)=>{
                                      return (
                                      <>
                                      <Slide key={product.name} direction="up" in={true}>
                                        <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
                                          <Card
                                            className={styles.card}
                                            onClick={() => productClickHandler(product)}
                                          >
                                            <CardActionArea>
                                              <CardMedia
                                                component="img"
                                                alt={product.name}
                                                onError={imageOnErrorHandler}
                                                image={`${baseURL}/` + product.image}
                                                className={styles.media}
                                              />
                                              <div style={{ backgroundColor: "white",  width: "auto", position: "absolute", bottom: "14px", right: "2px", padding: "5px",fontSize:'1.2em' }}>
                                                <h3 style={{ margin: "0px" }}>{selectedCurrency}{product.price}</h3>
                                              </div>
                    
                                              <div className={styles.veg_img} >
                                                {/* {product.cat_type.toUpperCase() === "VEG" ?
                                                  <img
                                                    src="/images/veg.png"
                                                    width="30px" height="30px"
                                                  />
                                                  : <img src="/images/Non-veg.png" width="30px" height="30px" />
                    
                                                } */}
                    
                                              </div>
                                              <CardContent className={styles.cardFooter} style={{display:"block"}}>
                                                <Box className={styles.foot}>
                                                  <Typography
                                                    gutterBottom
                                                    className={styles.prod_title}
                                                    variant="h6"
                                                    color="primary"
                                                    component="p"
                                                    // style={{width:"150px"}}
                                                  >
                                                    {product.name}
                                                  </Typography>
      
                                                  {/* <Typography
                                                    gutterBottom
                                                    variant="p"
                                                    color=""
                                                    component="p"
                                                  >
                                                      {product.calories}cal
                                                  </Typography> */}
                                                </Box >
                                              </CardContent>
                                            </CardActionArea>
                                          </Card>
                                        </Grid>
                                      </Slide>
                                      </>)})
                                       })
                                  }
      
                                </Grid>
                                  <Box style={{display:"flex",justifyContent:"space-between",alignItems:"center",position: "fixed",bottom:"0px",width: "95%",padding:"28px"}} > 
                                    <Button
                                    variant="contained"
                                    onClick={()=>setItemGrpId("")}
                                    color="error"
                                    className={styles.largeButton}
                                    id='choose_clbtn'
                                    >Back</Button>
      
                                    <Button
                                    variant="contained"
                                    onClick={()=>setItemGrpId("")}
                                    className={styles.largeButton}
                                    color="primary"
                                    id='nextbtn'
                                    >Next</Button>
                                  </Box>
                            </Dialog>
                    </>
                      )
                })
              )  : (
               ItemsWithGrp && ItemsWithGrp.map((it) =>{
                return (<>
                  <Slide key={it.name} direction="up" in={true} >
                  
                      <Card
                        className={styles.card}
                        onClick={() =>it["userId"]?productClickHandler(it):itemGroupHandle(it.id)}
                        style={{width:"205px",height:"260px"}}
                        id="product"
                      >
                        <CardActionArea style={{display:"flex",flexDirection:"column",paddingTop:"4px",flexWrap:"wrap"}}>
                          <CardMedia
                            component="img"
                            alt={it.name}
                            onError={imageOnErrorHandler}
                            image={`${baseURL}/` + it.image}
                            className={styles.media}
                            id='img-product'
                          />
                          <span id="pri">
                          {selectedCurrency} {it.price}
                          </span>
                          <CardContent className={styles.cardFooter}>
                            <Box className={styles.foot}>
                              <Typography
                                gutterBottom
                                className={styles.prod_title}
                                variant="h6"
                                color="primary"
                                component="p"
                                id="txtf"

                              >
                                {it.name} <br/>
                               

                              </Typography>
                              {/* <Typography
                                variant="subtitle1"
                                className={styles.prod_cal}
                                component="p"
                              >
                                {product.calorie} Cal
                              </Typography> */}
                               
                            </Box >
                          </CardContent>
                        </CardActionArea>
                      </Card>
                
                  </Slide>

                <Dialog
                onClose={()=>setItemGrpId("")}
                aria-labelledby="max-width-dialog-title"
                fullScreen="sm"
                open={itemGrpId===it.id}
                fullWidth={true}
                // maxWidth="sm"
                      maxWidth={state.widthScreen ? 'lg' : 'md'}
                      > 
                       <AppBar sx={{ position: 'relative',marginBottom:"18px" }}>
                              <Toolbar>
                                {/* <IconButton
                                  edge="start"
                                  color="error"
                                  style={{fontSize:"18px"}}
                                  onClick={()=>setItemGrpId("")}
                                  aria-label="close"
                                >
                                Back
                                </IconButton> */}
                                <Typography style={{marginLeft:"25px"}} color="secondary" sx={{ ml: 2, flex: 1 }} variant="h5" component="h5">
                                  {it.name}
                                </Typography>
                              </Toolbar>
                            </AppBar>

                          <Grid container spacing={1}  sx={{ height:"80%",padding:"15px" }}>

                            {
                              filterItmGrp.filter(p=>p.id===itemGrpId).map((item) =>{
                               return item.items.elements.filter(p=>p.available).map((product)=>{
                                return (
                                <>
                                <Slide key={product.name} direction="up" in={true}>
                                  <Grid item xl={2} lg={3} md={3} sm={4} xs={6}>
                                    <Card
                                      className={styles.card}
                                      onClick={() => productClickHandler(product)}
                                    >
                                      <CardActionArea>
                                        <CardMedia
                                          component="img"
                                          alt={product.name}
                                          onError={imageOnErrorHandler}
                                          image={`${baseURL}/` + product.image}
                                          className={styles.media}
                                        />
                                        <div style={{ backgroundColor: "white",  width: "auto", position: "absolute", bottom: "14px", right: "2px", padding: "5px",fontSize:'1.2em' }}>
                                          <h3 style={{ margin: "0px" }}>{selectedCurrency}{product.price}</h3>
                                        </div>
              
                                        <div className={styles.veg_img} >
                                          {/* {product.cat_type.toUpperCase() === "VEG" ?
                                            <img
                                              src="/images/veg.png"
                                              width="30px" height="30px"
                                            />
                                            : <img src="/images/Non-veg.png" width="30px" height="30px" />
              
                                          } */}
              
                                        </div>
                                        <CardContent className={styles.cardFooter} style={{display:"block"}}>
                                          <Box className={styles.foot}>
                                            <Typography
                                              gutterBottom
                                              className={styles.prod_title}
                                              variant="h6"
                                              color="primary"
                                              component="p"
                                              // style={{width:"150px"}}
                                            >
                                              {product.name}
                                            </Typography>

                                            {/* <Typography
                                              gutterBottom
                                              variant="p"
                                              color=""
                                              component="p"
                                            >
                                                {product.calories}cal
                                            </Typography> */}
                                          </Box >
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                  </Grid>
                                </Slide>
                                </>)})
                                 })
                            }

                          </Grid>
                            <Box style={{display:"flex",justifyContent:"space-between",alignItems:"center",position: "fixed",bottom:"0px",width: "95%",padding:"28px"}} > 
                              <Button
                              variant="contained"
                              onClick={()=>setItemGrpId("")}
                              color="error"
                              className={styles.largeButton}
                              id='choose_clbtn'
                              >Back</Button>

                              <Button
                              variant="contained"
                              onClick={()=>setItemGrpId("")}
                              className={styles.largeButton}
                              color="primary"
                              id='nextbtn'
                              >Next</Button>
                            </Box>
                      </Dialog>
              </>
                )
            })
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      </Box>

      <Box className='orderscreen_foot'>
        <Box>
          {/* <Box className={[styles.bordered, styles.space]} style={{ textAlign: 'center', fontSize: '26px', lineHeight: "30px" }}>
            My Order - {orderType} | Tax: {selectedCurrency}{taxPrice} | Total: {selectedCurrency}{totalPrice} |
            Items: {itemsCount}
          </Box> */}
          {/* <Box className={[styles.row, styles.around, styles.space]}>
            <Button
              onClick={() => {
                clearOrder(dispatch);
                props.history.push('/?' + window.location.href.split('?')[1]);
              }}
              variant="outlined"
              color="primary"
              className={styles.largeButton}
              id='choose_clbtn'
            >
              Cancel 
            </Button>

            <Button
              onClick={previewOrderHandler}
              variant="contained"
              color="primary"
              disabled={orderItems.length === 0}
              className={styles.largeButton}
            >
              NEXT
            </Button>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
}
