import Axios from 'axios';
import {
  CATEGORY_LIST_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  ORDER_ADD_ITEM,
  ORDER_REMOVE_ITEM,
  ORDER_CLEAR,
  TABLE_NUMBER,
  ORDER_SET_PAYMENT_TYPE,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_QUEUE_LIST_REQUEST,
  ORDER_QUEUE_LIST_SUCCESS,
  ORDER_QUEUE_LIST_FAIL,
  SCREEN_SET_WIDTH,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  GENERATE_QRCODE_REQUEST,
  GENERATE_QRCODE_SUCCESS,
  GENERATE_QRCODE_FAIL,
   FETCH_QRCODE_REQUEST,
  FETCH_QRCODE_SUCCESS,
  FETCH_QRCODE_FAIL,

FETCH_USERDATA_REQUEST,
FETCH_USERDATA_SUCCESS,
FETCH_USERDATA_FAIL,

FETCH_USERSETTING_REQUEST,
FETCH_USERSETTING_SUCCESS,
FETCH_USERSETTING_FAIL,

UPDATE_ORDER,
UPDATE_ORDER_SUCCESS,
UPDATE_ORDER_FAIL,

FETCH_ORDER,
FETCH_ORDER_SUCCESS,
FETCH_ORDER_FAIL,

} from './constants';
import { useContext } from 'react';
import { Store } from './Store';
import config,{getParameterByName,merchantCode} from './Configs'

let baseServer = window.location.href.indexOf('localhost') > 0 ?'https://sok.menulive.in':window.location.origin;

  // const baseURL = getParameterByName('serve_url')?getParameterByName('serve_url'):"http://localhost:5000";
  // const userId = getParameterByName('userid');
   const getCurrency = getParameterByName('currency');
   // const payURL=getParameterByName('pay_url');
   console.log(getCurrency);
    let userDetails=[];
    const baseURL = config.baseURL;
    const cmsURL="https://cms.digitallive24.com";
    const payURL="https://pay.digitallive24.com";
  const userName=getParameterByName('userName');

    // const getCatByUser = 'https://apps.digitallive24.com/api/categories?userId=625ed8a6f5b364ec758c1f0b'
    console.log(`${baseURL}/api/orders`);

    export const fetchOrder = async (dispatch, orderId) => {
      // 
      dispatch({ type: FETCH_ORDER });
      try {
        const { data } = await Axios.get(`${baseURL}/api/orders/${orderId}?merchantCode=${merchantCode}`);
        dispatch({
          type: FETCH_ORDER_SUCCESS,
          payload: data,
        });
      } catch (error) {
        dispatch({
          type: FETCH_ORDER_FAIL,
          payload: error.message,
        });
      }
    };



    export const fetchUpdateOrder = async (dispatch, orderId) => {
      // 
      dispatch({ type: ORDER_CREATE_REQUEST });
      try {
        const { data } = await Axios.get(`${baseURL}/api/orders/${orderId}?merchantCode=${merchantCode}`);
        dispatch({
          type: ORDER_CREATE_SUCCESS,
          payload: data,
        });
        dispatch({
          type: ORDER_CLEAR,
        });
      } catch (error) {
        dispatch({
          type: ORDER_CREATE_FAIL,
          payload: error.message,
        });
      }
    };



    export const updateOrder = async (dispatch, order,orderId) => {
     
      try {
        const { data } = await Axios.put(`${baseURL}/api/orders/${orderId}?merchantCode=${merchantCode}`, order);
              console.log(data);
        data?fetchUpdateOrder(dispatch,orderId):console.log("err");
      } catch (error) {
        console.log(error);
      }
    };





export const generateQrCode= async (dispatch,orderData)=>{
        dispatch({type:GENERATE_QRCODE_REQUEST});
        try{
          const {data}= await Axios.post(`${baseURL}/api/new-order-qr`,orderData);
          console.log(data);
         return dispatch({
            type: GENERATE_QRCODE_SUCCESS,
            payload:data,
          });
        }
        catch(error){
        return  dispatch({
            type:GENERATE_QRCODE_FAIL,
            payload:error.message,
          });

      }
   };


   export const getUserData= async (dispatch)=>{
    dispatch({type:FETCH_USERDATA_REQUEST});
    try{
      // const {data}= await Axios.get(`${cmsURL}/api/users/user-public-data/${userName}`);
      const {data}= await Axios.get(`${baseURL}/api/settings/merchants/${merchantCode}/open`);
      console.log(data);
      userDetails.push(data[0]);
      // getUserSettings(dispatch,data[0]._id);
     return dispatch({
        type: FETCH_USERDATA_SUCCESS,
        payload:data,
      });
    }
    catch(error){
      console.log(error);
    return  dispatch({
        type:FETCH_USERDATA_FAIL,
        payload:error.message,
      });

  }
};

   

export const getUserSettings= async (dispatch,id)=>{
    console.log(userDetails);
        dispatch({type:FETCH_USERSETTING_REQUEST});
        try{
          const {data}= await Axios.get(`${cmsURL}/api/user/settings-pub/${id}`);
          console.log(data.message);
         return dispatch({
            type: FETCH_USERSETTING_SUCCESS,
            payload:data.message,
          });
        }
        catch(error){
        return  dispatch({
            type:FETCH_USERSETTING_FAIL,
            payload:error.message,
          });

      }
   };

export const getCheckoutUrl= async (dispatch,orderData)=>{
        dispatch({type:GENERATE_QRCODE_REQUEST});
        try{
          const {data}= await Axios.post(`${payURL}/api/checkout`,orderData);
          console.log(data);
         return dispatch({
            type: GENERATE_QRCODE_SUCCESS,
            payload:data,
          });
        }
        catch(error){
        return  dispatch({
            type:GENERATE_QRCODE_FAIL,
            payload:error.message,
          });

      }
   };

export const fetchQrData= async (dispatch,bdy)=>{
        dispatch({type:FETCH_QRCODE_REQUEST});

        try{
          const {data}= await Axios.post(`${baseURL}/api/fetch-order-qr`,bdy);
          console.log(data);
         return data;
        }
        catch(error){
        return  error;

      }
   };
   export const closeQrCode= async (dispatch,bdy)=>{
        dispatch({type:GENERATE_QRCODE_REQUEST});
        try{
          const {data}= await Axios.post(`${baseURL}/api/close-order-qr`,bdy);
          console.log(data);
         return dispatch({
            type: GENERATE_QRCODE_SUCCESS,
            payload:data,
          });
        }
        catch(error){
        return  dispatch({
            type:GENERATE_QRCODE_FAIL,
            payload:error.message,
          });

      }
   };





   export const listCategories = async (dispatch) => {
    console.log(userDetails);
    dispatch({ type: CATEGORY_LIST_REQUEST });
    try {
      // USPIZZA-KEMP

      const { data } = await Axios.get(baseURL+"/api/categories?merchantCode="+merchantCode);
      return dispatch({
        type: CATEGORY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      return dispatch({
        type: CATEGORY_LIST_FAIL,
        payload: error.message,
      });
    }
  };

  export const listProducts = async (dispatch, category = '' ) => {
    console.log(userDetails);
    // console.log(category,userId);
    dispatch({ type: PRODUCT_LIST_REQUEST });
    try {
    
      if(category){
       const {data}  = await Axios.get(`${baseURL}/api/categories/${category}/items?merchantCode=${merchantCode}`);
       return dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload:data,
      });

      }else{
       const {data} = await Axios.get(`${baseURL}/api/products?merchantCode=${merchantCode}`);
       return dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload:data,
      });
      }
    } catch (error) {
      return dispatch({
        type: PRODUCT_LIST_FAIL,
        payload: error.message,
      });
    }
  };

  export const createOrder = async (dispatch, order) => {
    dispatch({ type: ORDER_CREATE_REQUEST });
    // if(order){order.userId=userDetails[0]._id}
    try {
      const { data } = await Axios.post(`${baseURL}/api/orders?merchantCode=${merchantCode}`, order);
      // data?:console.log("______");
      console.log(data);
      dispatch({
        type: ORDER_CREATE_SUCCESS,
        payload: data,
      });
      dispatch({
        type: ORDER_CLEAR,
      });
    } catch (error) {
      dispatch({
        type: ORDER_CREATE_FAIL,
        payload: error.message,
      });
    }
  };

export const setTableNumber= async (dispatch, orderType) => {
  return dispatch({
    type: TABLE_NUMBER,
    payload: orderType,
  });
};

export const setPaymentType = async (dispatch, paymentType) => {
  return dispatch({
    type: ORDER_SET_PAYMENT_TYPE,
    payload: paymentType,
  });
};

export const clearOrder = async (dispatch) => {
  return dispatch({
    type: ORDER_CLEAR,
  });
};

export const addToOrder = async (dispatch, item) => {
  return dispatch({
    type: ORDER_ADD_ITEM,
    payload: item,
  });
};
export const removeFromOrder = async (dispatch, item) => {

// const {item} =await Axios.delete(`${baseURL}/api/orders/${item}`)
  return dispatch({
    type: ORDER_REMOVE_ITEM,
    payload: item,
  });
};

export const listQueue = async (dispatch) => {
  console.log(userDetails);
  dispatch({ type: ORDER_QUEUE_LIST_REQUEST });
  try {
    const { data } = await Axios.get(`${baseURL}/api/order/queue/${userDetails[0]._id}`);
    dispatch({ type: SCREEN_SET_WIDTH });
    return dispatch({
      type: ORDER_QUEUE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    return dispatch({
      type: ORDER_QUEUE_LIST_FAIL,
      payload: error.message,
    });
  }
};

export const listOrders = async (dispatch) => {
  console.log(userDetails);
    dispatch({ type: ORDER_LIST_REQUEST });
    try {
      const { data } = await Axios.get(`${baseURL}/api/orders?merchantCode=${merchantCode}`);
      dispatch({ type: SCREEN_SET_WIDTH });
      return dispatch({
        type: ORDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      return dispatch({
        type: ORDER_LIST_FAIL,
        payload: error.message,
      });
    }
  };
